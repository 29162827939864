import React from "react";
import ab_img_1 from "../../asset/image/About_main_banner.webp";
import LazyImage from "../loader/LazyImage";

function AboutBanner() {
  return (
    <div className="about_banner_contain">
      <div className="ab_ban_img">
        <LazyImage actualImageSrc={ab_img_1} Alt="ab_ban_img" />
      </div>
      <section className="ab__content_sec">
        <div className="ab_cont_1">
          <h1 className="ab_title">ABOUT GLOBAL KING.</h1>
          <p className="ab_sub_para">
            For over 12 years, GKBIM has been providing exceptional support to
            architects, consultants, and MEP Contractors. Our expertise in
            Building Information Modelling (BIM) ensures seamless collaboration,
            delivering innovative solutions that enhance project efficiency and
            precision across all disciplines.{" "}
          </p>
          <p className="ab_para">
            {" "}
            Our company plays a pivotal role in revolutionizing architectural,
            structural, and MEP engineering through the strategic application of
            BIM technologies. At Global King, we take pride in our extensive
            experience, offering cutting-edge BIM services that empower clients
            to optimize the efficiency and effectiveness of their projects. Our
            team, consisting of skilled architects, engineers, and BIM
            specialists, is committed to delivering tailored solutions that meet
            the unique demands of each project. As an industry trailblazer, our
            mission is to shape the future of construction by leveraging the
            transformative power of BIM technologies.
          </p>

          <h1 className="ab_title">What We Do</h1>
          <p className="ab_para">
            At Global King, we leverage cutting-edge BIM (Building Information
            Modelling) technologies to streamline the design and construction
            process, enhancing precision and eliminating inefficiencies.{" "}
          </p>
          <p className="ab_para">
            Our use of advanced BIM tools ensures that potential clashes between
            Architectural, Structures and MEP systems are detected early,
            allowing for timely resolutions that reduce costly delays during
            construction.{" "}
          </p>
          <p className="ab_para">
            Our BIM services integrate all building systems into a highly
            detailed 3D model, fostering real-time collaboration across
            multidisciplinary teams. This holistic approach not only improves
            design accuracy but also mitigates project risks by providing a
            clear visual representation of complex systems before the physical
            build begins. Through these technologies, we enhance coordination,
            optimize workflows, and ensure smooth project delivery, consistently
            meeting both performance and deadline targets.{" "}
          </p>
        </div>
        <div className="ab_cont_2">
          <h1 className="ab_title">Mission</h1>
          <p className="ab_para">
            To think boldly, listen attentively and create passionately.
          </p>
          {/* <h1 className="ab_title">Mission</h1>
          <p className="ab_para">
            To think boldly, listen attentively and create passionately.
          </p> */}
          <h1 className="ab_title">Values</h1>
          <p className="ab_para">
            Empowering projects through cutting-edge BIM solutions. Transforming
            visions into reality with expert BIM consulting services
          </p>
        </div>
      </section>
    </div>
  );
}

export default AboutBanner;
