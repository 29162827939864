import React from "react";
import SP_Image from "../asset/image/contact_img.webp";
import solution from "../asset/image/solution-offered/gkbim-images-04.png";
import solution_one from "../asset/image/solution-offered/gkbim-images-05.png";
import solution_two from "../asset/image/solution-offered/gkbim-images-06.png";
import solution_three from "../asset/image/solution-offered/gkbim-images-08.png";
import solution_four from "../asset/image/solution-offered/gkbim-images-11.png";

import { Container } from "@mui/material";
import "../asset/style/SolutionOffered.css";

function SolutionsOffered() {
  return (
    <div className="sp_container">
      <div className="sp_banner">
        <img
          src={SP_Image}
          alt="service_img"
          width=""
          height=""
          loading="lazy"
        />
        <div className="sp_content">
          <h1 className="sp_title">Solutions Offered</h1>
          <p className="sp_para">
            Explore our services to see how GLOBAL KING can make your next
            project successful.
          </p>
        </div>
      </div>
      <Container
        maxWidth={"lg"}
        sx={{ position: "relative", paddingTop: "50px" }}
      >
        <section className="moderl-sec">
          <div className="solution-offered">
            <div className="solution-text-area">
              <div className="solution-text">
                <h1 className="solution-header">BIM MODELLING</h1>
                <p className="solution-paragraph">
                  <strong>
                    RIBA Stage 1 (Preparation and Brief) – LOD 100 (Conceptual
                    Design):
                  </strong>
                  At this stage, we establish the project's high-level design
                  concept with basic shapes and spatial layouts, outlining
                  general locations and orientations of MEP systems without
                  detailed specifics. This phase defines the project's goals,
                  requirements, and constraints in collaboration with
                  stakeholders.
                </p>
                <br />
                <p className="solution-paragraph">
                  <strong>
                    RIBA Stage 2 (Concept Design) – LOD 200 (Schematic Design):
                  </strong>
                  In this phase, we develop the preliminary design with
                  approximate sizes and placements of MEP systems, enabling
                  early coordination and clash detection. Basic layouts and
                  preliminary quantities are defined, aligning design intent
                  with project objectives and consultant input.
                </p>
                <br />
                <p className="solution-paragraph">
                  <strong>
                    RIBA Stage 3 (Developed Design) – LOD 300 (Design
                    Development):
                  </strong>
                  stage involves detailed design development, providing accurate
                  dimensions and specifications for all MEP components. The
                  model is coordinated and finalized to meet Issue for
                  Construction (IFC) standards, ready for construction
                  documentation with precise real-world information for
                  installation and fabrication.
                </p>
              </div>
            </div>
            <div className="solution-img-area">
              <div className="solution-child-area">
                <img src={solution} alt="solution" className="solution_img" />
              </div>
            </div>
          </div>
        </section>
        <br />
        <section className="moderl-sec">
          <div className="solution-offered">
            <div className="solution-text-area">
              <div className="solution-text">
                <h1 className="solution-header">BIM MODELLING</h1>
                <p className="solution-paragraph">
                  <strong>
                    RIBA Stage 1 (Preparation and Brief) – LOD 100 (Conceptual
                    Design):
                  </strong>
                  At this stage, we establish the project's high-level design
                  concept with basic shapes and spatial layouts, outlining
                  general locations and orientations of MEP systems without
                  detailed specifics. This phase defines the project's goals,
                  requirements, and constraints in collaboration with
                  stakeholders.
                </p>
                <br />
                <p className="solution-paragraph">
                  <strong>
                    RIBA Stage 2 (Concept Design) – LOD 200 (Schematic Design):
                  </strong>
                  In this phase, we develop the preliminary design with
                  approximate sizes and placements of MEP systems, enabling
                  early coordination and clash detection. Basic layouts and
                  preliminary quantities are defined, aligning design intent
                  with project objectives and consultant input.
                </p>
                <br />
                <p className="solution-paragraph">
                  <strong>
                    RIBA Stage 3 (Developed Design) – LOD 300 (Design
                    Development):
                  </strong>
                  stage involves detailed design development, providing accurate
                  dimensions and specifications for all MEP components. The
                  model is coordinated and finalized to meet Issue for
                  Construction (IFC) standards, ready for construction
                  documentation with precise real-world information for
                  installation and fabrication
                </p>
              </div>
            </div>
            <div className="solution-img-area">
              <div className="solution-child-area">
                <img
                  src={solution_one}
                  alt="solution_one"
                  className="solution_img"
                />
              </div>
            </div>
          </div>
        </section>
        <br />
        <section className="moderl-sec">
          <div className="solution-offered">
            <div className="solution-text-area">
              <div className="solution-text">
                <h1 className="solution-header">BIM MODELLING</h1>
                <p className="solution-paragraph">
                  <strong>
                    RIBA Stage 4 (Technical Design) – LOD 400
                    (Construction-Ready Models):
                  </strong>
                  construction models are developed to LOD 400, which
                  incorporates fabrication details needed for pre-construction
                  and construction workflows. These models include precise
                  elements for installation, assembly, and coordination, serving
                  as a bridge between design and construction phases, aligning
                  with RIBA Stage 4, where detailed design work transitions into
                  ready -to-build specifications.
                </p>
                <br />
                <p className="solution-paragraph">
                  <strong>
                    RIBA Stage 5 (Construction) – LOD 500 (As-Built Models):
                  </strong>
                  We also provide models at LOD 500, representing the final
                  as-built conditions. This aligns with RIBA Stage 5, where the
                  construction phase is completed, and as -built conditions are
                  captured. These comprehensive models allow for precise
                  installation, fabrication, and long-term facility management,
                  helping to minimize errors and ensure a streamlined
                  construction process.
                </p>
              </div>
            </div>
            <div className="solution-img-area">
              <div className="solution-child-area">
                <img
                  src={solution_two}
                  alt="solution_one"
                  className="solution_img"
                />
              </div>
            </div>
          </div>
        </section>
        <br />
        <section className="moderl-sec">
          <div className="solution-offered">
            <div className="solution-text-area">
              <div className="solution-text">
                <h1 className="solution-header">BILL OF QUANTITIES</h1>
                <p className="solution-paragraph">
                  <strong>MEP Quantity Surveying Services:</strong>
                  GK is a leading provider of precise bills of quantities. With
                  a unique combination of traditional quantity surveying
                  expertise and hands-on experience in commercially managing
                  complex projects, we ask the right questions and leverage our
                  knowledge to deliver value on every project.
                </p>
                <br />
                <h2>We expertise in:</h2>
                <p className="solution-paragraph">
                  <strong>Preliminary Bill of Quantities (PBQ):</strong>
                  Estimates initial project costs, including site preparation
                  and early-stage activities.
                </p>
                <br />
                <p className="solution-paragraph">
                  <strong>Approximate Bill of Quantities (ABQ):</strong>
                  Provides cost estimates for different construction phases,
                  aiding in budgeting and financing.
                </p>
                <p className="solution-paragraph">
                  <strong>Revised Bill of Quantities (RBQ):</strong>
                  Updates the BoQ to reflect changes in project scope or design.
                </p>
                <p className="solution-paragraph">
                  <strong>Firm Bill of Quantities (FBQ):</strong>
                  Finalized and agreed-upon BoQ used for tendering and contract
                  execution.
                </p>
                <p className="solution-paragraph">
                  <strong>Provisional Bill of Quantities (PBQ):</strong>
                  Includes items with uncertain quantities or costs, useful when
                  exact details are unavailable.
                </p>
                <p className="solution-paragraph">
                  <strong>Contractor’s Bill of Quantities (CBQ):</strong>
                  Prepared by contractors, detailing their specific requirements
                  and pricing for the project.
                </p>
              </div>
            </div>
            <div className="solution-img-area">
              <div className="solution-child-area">
                <img
                  src={solution_three}
                  alt="solution_one"
                  className="solution_img"
                />
              </div>
            </div>
          </div>
        </section>
        <br />
        <section className="moderl-sec">
          <div className="solution-offered">
            <div className="solution-text-area">
              <div className="solution-text">
                <h1 className="solution-header">BIM MODELLING</h1>
                <p className="solution-paragraph">
                  <strong>Authority Compliance:</strong>: With the growing
                  prominence of BIM in the construction industry, Dubai
                  Municipality has mandated BIM for authority approvals. We
                  provide comprehensive solutions to convert traditional CAD
                  designs into a fully integrated BIM environment, ensuring
                  compliance with local regulations and facilitating smoother
                  project approvals.
                </p>
                <br />
                <p className="solution-paragraph">
                  <strong>We expertise in:</strong>
                  We develop highly detailed, parametric Revit families for MEP
                  components, tailored to the specific needs of your project.
                  Our Revit families are fully customizable, allowing for
                  flexibility in design while maintaining accuracy and
                  efficiency throughout the modelling process.
                </p>
                <br />
                <p className="solution-paragraph">
                  <strong>BIM Model Validation:</strong>
                  We ensure that BIM models comply with project standards,
                  codes, and client requirements, providing a thorough review to
                  detect errors or inconsistencies.
                </p>
              </div>
            </div>
            <div className="solution-img-area">
              <div className="solution-child-area">
                <img
                  src={solution_four}
                  alt="solution_one"
                  className="solution_img"
                />
              </div>
            </div>
          </div>
        </section>
        <br/>
      </Container>
    </div>
  );
}

export default SolutionsOffered;
