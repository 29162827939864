import React from "react";
import "../asset/style/Footer.css";
import { Link } from "react-router-dom";
import Footer_logo from "../asset/image/cs logo.png";
import { Container } from "@mui/material";

function Footer() {
  return (
    <footer className="footer_main_container">
      <div className="footer_container_2">
        <ul>
          <li>
            <Link className="footer_menu_item" to={"/service"}>
              <div className="footer_menu_2">
                <h2 className="footer_subtitle">Learn More</h2>
                <h1 className="footer_title">Service</h1>
                <p className="footer_text">
                  Discover the Harmony in Our Services.
                </p>
              </div>
            </Link>
          </li>
          <li>
            <Link className="footer_menu_item" to={"/about"}>
              <div className="footer_menu_2">
                <h2 className="footer_subtitle">Learn More</h2>
                <h1 className="footer_title">About us</h1>
                <p className="footer_text">
                  Crafting Success Stories through Innovation.
                </p>
              </div>
            </Link>
          </li>
          <li>
            <Link
              className="footer_menu_item"
              to="https://docs.google.com/forms/d/e/1FAIpQLSeurPFNGcMrOH0j_sIX_sM2lK9D2MUZTrxnt0kEm_bkItClFw/viewform"
              target="_blank"
            >
              <div className="footer_menu_2">
                <h2 className="footer_subtitle">Learn More</h2>
                <h1 className="footer_title">Career</h1>
                <p className="footer_text">Building Futures with GlobalKing.</p>
              </div>
            </Link>
          </li>
          <li>
            <Link className="footer_menu_item" to={"/contact"}>
              <div className="footer_menu_2">
                <h2 className="footer_subtitle">Learn More</h2>
                <h1 className="footer_title">Contact us</h1>
                <p className="footer_text">
                  Reach Out for Inquiries & Collaboration.
                </p>
              </div>
            </Link>
          </li>
        </ul>
      </div>
      <div className="address-details">
        <Container  maxWidth={"xl"}>
          <div className="address-dtd-sec">
            <div>
              <h2>UAE Contact Details</h2>
              <address>
                Data Hub Technologies L.L.C <a href="mailto:globalking@gkbim.com?body=My custom mail body">globalking@gkbim.com</a> Primary
                Contact: 056-6052366 Mobile No: 050-2721066 Gulf A2 Tower-4 th
                floor-401 - Oud Metha Rd - Dubai
              </address>
            </div>
            <div>
              <h2>India Contact Details</h2>
              <address>
                GK BIM Solutions <a href="mailto:globalking@gkbim.com?body=My custom mail body">globalking@gkbim.com</a> Primary Contact: <a href="tel:+91
                6379868711"> +91 6379868711</a> 82, Bharathidasan Salai, Sangillyandapuram,
                Tiruchirappalli, Tamil Nadu 620001, India
              </address>
            </div>
          </div>
        </Container>
      </div>
      <div className="copy_right">
        <div className="cr_policy">
          <Link className="cr_link" href="/#">
            Privacy Policy
          </Link>
          <Link className="cr_link">©2025 copyright Inc.</Link>
        </div>
        <div className="cleverso_icon">
          <a
            className="cr_link"
            style={{ textAlign: "end" }}
            href="https://www.cleversoindia.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Footer_logo} alt="footer" width="50px" height="30px" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
