import React from "react";
import SP_Image from "../asset/image/contact_img.webp";
// import SP_main_img from '../asset/image/service_img-1.jpg'
import "../asset/style/ServicePage.css";
import { Container } from "@mui/material";
import ServiceCard from "../components/service-section/ServiceCard";
import LazyImage from "../components/loader/LazyImage";
import cloud_img from "../asset/image/sevice-detail/construction-services.jpg";
import cloud_img_tran from "../asset/image/sevice-detail/gkbim-transparent.png";

// import LazyImage from '../components/loader/LazyImage';

function ServicePage() {
  return (
    <div className="sp_container">
      <div className="sp_banner">
        <img
          src={SP_Image}
          alt="service_img"
          width=""
          height=""
          loading="lazy"
        />
        <div className="sp_content">
          <h1 className="sp_title">Services</h1>
          <p className="sp_para">
            Explore our services to see how GLOBAL KING can make your next
            project successful.
          </p>
        </div>
      </div>
      <Container
        maxWidth={"lg"}
        sx={{ position: "relative", zIndex: 2, marginTop: "-80px" }}
      >
        <ServiceCard />
        <div className="cloud-sourse-main">
          <div className="cloud-text-header">
            <h1 className="header-service">Services Offered</h1>
          </div>
          <div className="cloud-sourse">
            <div className="cloud-text-aera">
              <h3 className="header-bhim">
                At GK BIM, we provide a comprehensive range of services,
                delivered by a highly skilled and experienced team of
                professionals.
              </h3>
              <p>
                Our team’s expertise ensures the highest level of accuracy and
                efficiency across all project stages, from conceptual design to
                final implementation. We specialise in delivering tailored
                solutions that meet the unique demands of each project in the
                Construction Industry, ensuring seamless integration systems,
                optimised performance, and compliance with industry standards.
              </p>
              <br />
              <ul className="servise-list">
                <h3 className="servise-offer">Services Offered</h3>
                <li>Architecture</li>
                <li>Structures</li>
                <li>Mechanical</li>
                <li>Electrical</li>
                <li>Plumbing</li>
                <li>Fire Protection</li>
              </ul>
            </div>
            <div className="cloud-image-aera">
              <div className="cloud-image-service">
                <LazyImage actualImageSrc={cloud_img} Alt="cloud_img"/>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="cloud-sourse">
          <div className="cloud-text-aera">
            <div className="cloud-text-aera-child">
              <h1 className="servise-offer">Cloud Services</h1>
              <p>
                Our cloud-based BIM platforms enable teams to work together
                seamlessly, regardless of location, allowing real-time updates,
                file sharing, and model coordination.
              </p>
              <br/>
              <p>
                Multiple stakeholders, including designers, engineers, and
                contractors, can access and work on the same model
                simultaneously, ensuring real-time updates and reducing the risk
                of miscommunication or data duplication.
              </p>
              <br/>
              <p>
                Cloud-based BIM tools streamline model management, offering
                efficient version control and enabling automatic clash detection
                across different disciplines. By centralizing all project
                information, the cloud platform helps to reduce delays, improve
                coordination, and enable faster decision-making, ultimately
                enhancing project delivery.
              </p>
              <br/>
              <p>
                Our cloud-based services are scalable to fit projects of any
                size, reducing the need for heavy IT infrastructure and
                maintenance. It’s a cost-effective solution that optimizes
                collaboration and project management for faster, more accurate
                outcomes.
              </p>
            </div>
          </div>
          <div className="cloud-image-aera">
            <LazyImage
              actualImageSrc={cloud_img_tran}
              className="cloud-img"
              Alt="cloud_img_tran"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ServicePage;
